<template>
    <section class="treatment-series">
        <tool-bar v-bind:lang.sync="lang">
            Treatment Series
            <template slot="buttons">

                <div class="level-item">
                    <button class="button"
                            v-on:click="createSeries">
                        <span class="icon is-small">
                            <font-awesome-icon icon="plus"></font-awesome-icon>
                        </span>
                        <span>Add a Series</span>
                    </button>
                </div>
                <div class="level-item">
                    <button class="button"
                            v-on:click="$router.push({ name: 'add-treatment' })">
                        <span class="icon is-small">
                            <font-awesome-icon icon="plus"></font-awesome-icon>
                        </span>
                        <span>Add a Treatment</span>
                    </button>
                </div>
                <div class="level-item">
                    <button class="button"
                            v-bind:class="{ 'is-loading': loading }"
                            v-bind:disabled="!edited"
                            v-on:click="save">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Save</span>
                    </button>
                </div>
            </template>
        </tool-bar>

        <!-- <div class="tabs is-small is-toggle is-toggle-rounded">
            <ul>
                <li v-bind:class="{ 'is-active': lang === 'en' }"
                    v-on:click="lang = 'en'">
                    <a>English</a>
                </li>
                <li v-bind:class="{ 'is-active': lang === 'zh-hk' }"
                    v-on:click="lang = 'zh-hk'">
                    <a>繁體中文</a>
                </li>
                <li v-bind:class="{ 'is-active': lang === 'zh-cn' }"
                    v-on:click="lang = 'zh-cn'">
                    <a>簡體中文</a>
                </li>
            </ul>
        </div> -->

        <article class="message is-dark">
            <div class="message-body" style="background-color: rgba(255, 255, 255, 1)">
                You can drag the treatment series below to reorder them. It will be reflected in the main website upon
                saving. You can enter and edit a treatment by clicking the links within a series.
            </div>
        </article>

        <div class="series-table">
            <section class="section"
                     v-if="treatmentSeries.length === 0">
                <div class="content has-text-grey has-text-centered">
                    <h1>
                        <font-awesome-icon icon="empty-set"></font-awesome-icon>
                    </h1>
                    <p>Nothing here yet. </p>
                    <button class="button is-text"
                            v-on:click="createSeries">
                        Create a series?
                    </button>
                </div>
            </section>
            <draggable v-model="treatmentSeries"
                       v-on:end="onDragEnd">
                <transition-group>
                    <article class="row treatment position-relative"
                             v-for="series in treatmentSeries"
                             v-bind:key="series._id">
                        <div class="bg-wrapper position-absolute d-none d-md-block">
                            <div class="container-fluid h-100">
                                <div class="row h-100 no-gutters">
                                    <div class="col-md-7 d-flex flex-column py-4 h-100"></div>
                                    <div class="col-md-5 cover h-100"
                                         v-bind:style="{ 'background-image': `url(${ $config.apiUrl }/${ $config.apiVersion }/salon/series/image/${ series.cover })` }"></div>
                                </div>
                            </div>
                        </div>
                        <div class="container">
                            <div class="row h-100 no-gutters">
                                <div class="col-md-7 d-flex flex-column py-4 order-1 order-md-0">
                                    <div class="">
                                        <h1 class="mb-2"
                                            v-html="series.title[lang]">
                                        </h1>

                                        <div class="btn-edit pb-2">
                                            <button class="button is-small" style="margin-right: 10px; "
                                                    v-on:click="editSeries(series._id)">
                                                <span class="icon is-small">
                                                    <font-awesome-icon icon="cog"></font-awesome-icon>
                                                </span>
                                                <span>Edit Series</span>
                                            </button>
                                            <button class="button is-small is-danger is-outlined btn-delete-series"
                                                    v-if="series._id !== '5bad92ff6459cc5033483ef0' && treatments.filter(treatment => treatment.series === series._id).length === 0"
                                                    v-on:click="deleteSeries(series)">
                                                <span class="icon is-small">
                                                    <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                                                </span>
                                                <span>Delete Series</span>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="row flex-1">
                                        <div class="col-md-6 d-flex flex-column align-items-stretch"
                                             v-for="n in Math.ceil(treatments.filter(treatment => treatment.series === series._id).length / rowCount)"
                                             v-bind:key="n">
                                            <div class="d-block item"
                                                 v-for="treatment in treatments.filter(treatment => treatment.series === series._id).slice((n - 1) * rowCount, n * rowCount)"
                                                 v-bind:key="treatment._id">
                                                <router-link
                                                    v-bind:to="{ name: 'edit-treatment', params: { treatmentId: treatment._id } }">
                                                    {{ sanitizeHtml(treatment.title[lang]) }}
                                                </router-link>
                                                <div class="btn-delete-treatment-wrapper">
                                                    <button class="button is-danger is-outlined btn-delete-treatment"
                                                            v-on:click="confirmDeleteTreatment(treatment)">
                                                        <span class="icon is-small">
                                                            <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5 d-none d-md-block"></div>
                                <div class="col-md-5 d-block d-md-none cover order-0 order-md-1"
                                     v-bind:style="{ 'background-image': `url(${ $config.apiUrl }/${ $config.apiVersion }/salon/series/image/${ series.cover })` }"></div>
                            </div>
                        </div>
                    </article>
                </transition-group>
            </draggable>
        </div>

        <b-modal has-modal-card
                 v-bind:active.sync="isCategoryModalActive">
            <modal-category target="salon"
                            v-bind:mode="categoryModalMode">
            </modal-category>
        </b-modal>

        <b-modal has-modal-card
                 v-bind:active.sync="isDeleteSeriesModalActive">
            <modal-treatment-series-delete
                v-bind:series="seriesToBeDeleted">
            </modal-treatment-series-delete>
        </b-modal>

        <modal-confirmation v-bind:callback="deleteTreatment"
                            v-model="isDeleteTreatmentModalActive">
            You are deleting a treatment named "{{ treatmentToBeDeleted ? treatmentToBeDeleted.title[lang] : "" }}". To
            continue, please confirm.
        </modal-confirmation>
    </section>
</template>

<script>
import * as sanitizeHtml from 'sanitize-html'
import draggable from 'vuedraggable'
import ModalCategory from '../modals/ModalCategory'
import ToolBar from '../layouts/ToolBar'
import ModalTreatmentSeriesDelete from '../modals/ModalTreatmentSeriesDelete'
import ModalConfirmation from '../modals/ModalConfirmation'

export default {
    name: 'TreatmentCategories',
    components: {
        ModalConfirmation,
        ModalTreatmentSeriesDelete,
        ToolBar,
        draggable,
        ModalCategory
    },
    data() {
        return {
            loading: false,
            edited: false,

            sortableOptions: {
                chosenClass: 'is-selected'
            },

            isCategoryModalActive: false,
            categoryModalMode: 'add',

            isDeleteSeriesModalActive: false,
            seriesToBeDeleted: null,

            isDeleteTreatmentModalActive: false,
            treatmentToBeDeleted: null,

            rowCount: 6,
            lang: 'en',

            treatmentSeries: []
        }
    },
    computed: {
        treatments() {
            return this.$store.state.salon.salon.treatments
        }
    },
    methods: {
        sanitizeHtml(htmlString) {
            return sanitizeHtml(
                htmlString,
                {
                    allowedTags: []
                }
            )
        },

        onDragEnd() {
            this.edited = true
        },

        createSeries() {
            this.$router.push({
                name: 'add-treatment-series'
            })
        },

        editSeries(seriesId) {
            this.$router.push({
                name: 'edit-treatment-series',
                params: {
                    seriesId: seriesId
                }
            })
        },

        deleteSeries(series) {
            this.isDeleteSeriesModalActive = true
            this.seriesToBeDeleted = series
        },

        confirmDeleteTreatment(treatment) {
            this.isDeleteTreatmentModalActive = true
            this.treatmentToBeDeleted = treatment
        },

        async deleteTreatment() {
            this.loading = true
            try {
                await this.$store.dispatch('salon/deleteTreatment', this.treatmentToBeDeleted._id)
            } catch (e) {
                this.loading = false
                return
            }
            this.loading = false
        },

        async save() {
            this.loading = true
            try {
                await this.$store.dispatch('salon/updateSalon', {
                    'series': this.treatmentSeries
                })
            } catch (e) {
                this.loading = false
                console.log(e)
            }
            this.loading = false
        }
    },
    mounted() {
        this.treatmentSeries = this.$store.state.salon.salon.series
    },
    watch: {
        '$store.state.salon.salon': {
            handler: function (newVal) {
                if (newVal.series) {
                    this.treatmentSeries = newVal.series
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
$theme-colors: (
    "dark": rgba(113, 113, 113, 1)
);
@import "~bootstrap/scss/bootstrap";

.series-table {
    background-color: rgba(255, 255, 255, 1);
    margin: 1rem;
}

.btn-edit {
    bottom: 15px;
    right: 15px;
    z-index: 1;
}

.btn-delete-treatment-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 5;

    display: flex;
    align-items: center;

    & > .btn-delete-treatment {
        background-color: rgba(255, 255, 255, 1);
    }
}

.btn-delete-series {
    background-color: rgba(255, 255, 255, 1);
}

.message {
    margin-top: 25px;
    margin-left: 25px;
    margin-right: 25px;
}

.treatment-series {
    position: relative;
    background-color: rgba(245, 245, 245, 1);

    & > .tabs {
        margin-top: 1rem;
        margin-bottom: 1rem;

        & > ul {
            display: flex;
            justify-content: center;
        }
    }

    .treatment {
        min-height: 30vh;
        margin-left: 1rem;

        & > .bg-wrapper {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }

        .item {
            position: relative;
            padding: 10px 0;
        }

        .cover {
            box-shadow: 25px 0 50px 0 rgba(255, 255, 255, 1) inset;
            background-color: rgba(225, 225, 225, 1);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
}
</style>
