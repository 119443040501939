<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Delete a treatment series</p>
        </header>
        <section class="modal-card-body">
            <div class="content">
                <p class="has-text-centered">This action is irreversible. Are you sure you want to delete this treatment
                    series? </p>
            </div>
            <div class="wrapper">
                <article class="row no-gutters treatment position-relative">
                    <div class="bg-wrapper position-absolute d-none d-md-block">
                        <div class="container-fluid h-100">
                            <div class="row h-100">
                                <div class="col-md-7 d-flex flex-column py-4 h-100"></div>
                                <div class="col-md-5 cover h-100"
                                     v-bind:style="{ 'background-image': `url(${ $config.apiUrl }/${ $config.apiVersion }/salon/series/image/${ series.cover })` }"></div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row h-100">
                            <div class="col-md-7 d-flex flex-column py-4 order-1 order-md-0">
                                <h1 class="mb-2">{{ series.title[lang] }}</h1>
                                <div class="row flex-1">
                                    <div
                                        class="col-md-6 d-flex flex-column justify-content-between align-items-stretch">

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5 d-none d-md-block"></div>
                            <div class="col-md-5 d-block d-md-none cover order-0 order-md-1"
                                 v-bind:style="{ 'background-image': `url(${ $config.apiUrl }/${ $config.apiVersion }/salon/series/image/${ series.cover })` }"></div>
                        </div>
                    </div>
                </article>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button"
                    v-on:click="$parent.close()">
                Cancel
            </button>
            <button class="button is-danger"
                    v-bind:class="{ 'is-loading': loading }"
                    v-on:click="deleteSeries">
                Delete
            </button>
        </footer>
    </div>
</template>

<script>
    export default {
        name: 'ModalTreatmentSeriesDelete',
        props: {
            series: {
                type: Object,
                require: true
            }
        },
        data() {
            return {
                loading: false,
                lang: 'en'

            }
        },
        methods: {
            async deleteSeries() {
                this.loading = true
                try {
                    await this.$store.dispatch('salon/deleteSeries', this.series._id)
                } catch (e) {
                    console.log(e)
                    this.loading = false
                    return
                }
                this.loading = false
                this.$parent.close()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-card-body {
        padding-left: 0;
        padding-right: 0;
    }

    .modal-card-foot {
        justify-content: flex-end;
    }

    .wrapper {
        $theme-colors: (
            "dark": rgba(113, 113, 113, 1)
        );
        @import "~bootstrap/scss/bootstrap";

        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: rgba(245, 245, 245, 1);

        .treatment {
            min-height: 30vh;
            background-color: rgba(255, 255, 255, 1);

            & > .bg-wrapper {
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }

            .item {
                padding: 10px 0;
            }

            .cover {
                box-shadow: 25px 0 50px 0 rgba(255, 255, 255, 1) inset;
                background-color: rgba(225, 225, 225, 1);
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
</style>
