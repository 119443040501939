<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ mode | capitalize }} a Category</p>
        </header>
        <section class="modal-card-body">
            <div class="field">
                <label class="label">Category Type</label>
                <b-select placeholder=""
                          v-model="type">
                    <option value="type" selected
                            v-if="target === 'shop'">
                        Product Type (產品類別)
                    </option>
                    <option value="type" selected
                            v-if="target === 'salon'">
                        Treatment Type (療程類別)
                    </option>
                </b-select>
            </div>
            <div class="field">
                <label class="label">Category Name (*required)</label>
                <b-switch v-model="enableTcToSc">
                    <strong>Auto Traditional-to-Simplified-Chinese conversion</strong>
                </b-switch>
                <b-field style="margin-top: .75rem;">
                    <p class="control" style="min-width: 90px; ">
                        <span class="button is-static" style="width: 100%; ">English</span>
                    </p>
                    <b-input type="text"
                             expanded
                             placeholder=""
                             v-model.trim="name['en']">
                    </b-input>
                </b-field>
                <b-field>
                    <p class="control">
                        <span class="button is-static">繁體中文</span>
                    </p>
                    <b-input type="text"
                             expanded
                             placeholder=""
                             v-model.trim="name['zh-hk']"
                             v-on:input="convertTcToSc">
                    </b-input>
                </b-field>
                <b-field>
                    <p class="control">
                        <span class="button is-static">簡體中文</span>
                    </p>
                    <b-input type="text"
                             expanded
                             placeholder=""
                             v-bind:disabled="enableTcToSc"
                             v-model.trim="name['zh-cn']">
                    </b-input>
                </b-field>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button"
                    v-on:click="$parent.close()">
                Cancel
            </button>
            <button class="button is-primary"
                    v-if="mode === 'add'"
                    v-bind:disabled="!allowSave"
                    v-bind:class="{ 'is-loading': loading }"
                    v-on:click="save">
                Save
            </button>
            <button class="button is-primary"
                    v-if="mode === 'edit'"
                    v-bind:disabled="!allowSave"
                    v-bind:class="{ 'is-loading': loading }"
                    v-on:click="update">
                Update
            </button>
        </footer>
    </div>
</template>

<script>
    const opencc = require('node-opencc')

    export default {
        name: 'ModalCategory',
        props: {
            target: {
                type: String,
                required: true,
                validator: (val) => {
                    return ['shop', 'salon'].includes(val)
                }
            },

            category: {
                type: Object
            }
        },
        data() {
            return {
                mode: 'add',
                loading: false,
                enableTcToSc: true,

                type: 'type',
                name: {
                    'en': '',
                    'zh-hk': '',
                    'zh-cn': ''
                }
            }
        },
        computed: {
            allowSave() {
                return this.type &&
                    this.name['en'] &&
                    this.name['zh-hk'] &&
                    this.name['zh-cn']
            }
        },
        methods: {
            convertTcToSc(val) {
                if (this.enableTcToSc) {
                    this.name['zh-cn'] = opencc.traditionalToSimplified(val)
                }
            },

            async save() {
                let temp = {
                    type: this.type,
                    name: this.name
                }
                this.loading = true

                try {
                    await this.$store.dispatch(`${this.target}/addCategory`, temp)
                } catch (e) {
                    console.log(e)
                    this.loading = false
                    return
                }
                this.loading = false
                this.$parent.close()
            },

            async update() {
                let temp = {
                    _id: this.category._id,
                    type: this.type,
                    name: this.name
                }
                this.loading = true

                try {
                    await this.$store.dispatch(`${this.target}/updateCategory`, temp)
                } catch (e) {
                    console.log(e)
                    this.loading = false
                    return
                }
                this.loading = false
                this.$parent.close()
            }
        },
        mounted() {
            if (this.category) {
                this.mode = 'edit'
                this.name = this.category.name
                this.type = this.category.type
                return
            }
            this.mode = 'add'
        }
    }
</script>

<style lang="scss" scoped>
    .modal-card-foot {
        justify-content: flex-end;
    }

    .label {
        font-size: 18px;
        margin-top: 1rem;
    }

    .coordinate-selector {
        & > .content {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .crosshair-wrapper {
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > .crosshair {
            font-size: 42px;
            z-index: 100;
        }
    }
</style>
